.property-skeleton {
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
  
    .image-skeleton {
      background-color: #e0e0e0;
      width: 100%;
      height: 150px;
    }
  
    .content-skeleton {
      padding: 15px;
  
      .title-skeleton,
      .description-skeleton,
      .details-skeleton {
        background-color: #e0e0e0;
        height: 20px;
        margin-bottom: 10px;
      }
  
      .title-skeleton {
        width: 70%;
      }
  
      .description-skeleton {
        width: 100%;
      }
  
      .details-skeleton {
        width: 50%;
      }
    }
  }
  
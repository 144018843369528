$desktop: 1200px; //xl
$laptop: 1024px; //lg
$tablet: 768px; //md
$phone: 480px; // sm
$smallphone: 100px; // xs
.carousel {
    min-height: 0;
    .slider_options_new {
        z-index: 1; 
        min-height: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        aspect-ratio: auto 2/1;
        @media screen and (max-width: $tablet) {
            z-index: 1; 
            min-height: 150px;
                aspect-ratio: auto 2/1;
        }
    }
    .carousel-control-next, .carousel-control-prev{
        width: auto;
    }
    @media screen and (max-width: $tablet) {
        min-height: 150px;
        z-index: 1; 
    }
    
}

.property-card {
    position: relative;
    overflow: hidden;
    box-shadow: 2px 2px 10px var(--extra-light);
    border-radius: 12px;

    img.card-img-top {
        position: relative;
        // min-height: 200px;
        // max-height: 200px;
        aspect-ratio: 8/5;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60%;
        width: 100%;
        opacity: 1;
        transition: 0.5s ease;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
    .card-body {
        padding: 0;
        position: absolute;
        bottom: -57px;
        width: 100%;
        left: 0;
        transition: all 0.5s ease;

        .property-hover {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--primary);
            color: var(--white);

            .property-details {
                padding: 10px 0 10px 15px;
                text-align: center;
                span {
                    font-size: 12px;
                    display: block;
                }
                &:last-child {
                    padding: 10px 15px 10px 0px;
                }
            }
        }
        .card-title {
            color: var(--white);
            font-size: 16px;
            padding: 0 15px;
            margin: 0;
            white-space: nowrap;
            font-weight: 600;
            text-shadow: 0 0 5px var(--dark), 0 0 5px var(--dark);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .card-text {
            color: var(--white);
            padding: 0 15px;
        }
        .price {
            span {
                font-size: 24px;
            }
        }
    }
    &:hover {
        .card-body {
            bottom: 0;
        }
    }
}

.booknow{
    .col:nth-child(2){
        padding: 0;
    }
    label{
        font-size: 12px;
    }
    
    .form-control{
        height: 42px;
        background-color: var(--light);
        border: 0;
        box-shadow: -1px 1px 1px var(--extra-light);
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 12px;
        width: 80%;
    }
    .total-box {
        border:1px solid #555;
        padding: 5px;
        margin-bottom: 5px;
        border-radius: 3px;
        background-color: var(--light);
    }
    .total-head {
        text-align: center;
    }
    .total-box span {
        //display:block;
    }
    #nightly-rates-ul {
        padding-left: 30px !important;
        list-style: circle outside initial !important;
    }
    .showRates, .showFees {
        cursor: pointer;
    }
    .displayHide {
        display: none;
    }
    .displayShow {
        display: block;
    }
    .fee-class-key {
        color: #555555;
        display: inline-block;
    }
    .bold-text {
        font-weight: 600;
    }
    .underline-text {
        text-decoration: underline;
    }
}
.booking-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.datepicker-wrap {
    border: 1;
    padding-left: 30px;
    // border-radius: 12px;
    // box-shadow: -1px 1px 1px;

}
// /* Changing the background color of selected date and date span */
// .CalendarDay__selected_span,
// .CalendarDay__selected {
//     background: var(--primary);
//     border: var(--primary);
//     border-color: var(--primary);
//     color: #fff; /* You can replace #fff with your preferred color */
// }

// /* Changing the border and background color of hovered date and date span */
// .CalendarDay__hovered_span,
// .CalendarDay__after_hovered_start {
//     background: var(--primary);
//     border-color: var(--primary);
// }



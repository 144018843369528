.custom-modal-style {
    max-width: 90vw !important; // !important to override default styles
  }
  /* For the Card */
.card-style {
    cursor: pointer;
    width: 100%;
    position: relative;
  }
  
  /* For the Card.Img */
  .card-img-style {
    height: 240px;
    object-fit: cover;
    width: 100%;
  }
  
  /* For the Card.Title */
  .card-title-style {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    width: 100%;
  }
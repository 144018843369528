$desktop: 1200px; //xl
$laptop: 1024px; //lg
$tablet: 768px; //md
$phone: 480px; // sm
$smallphone: 100px; // xs
.drp-calendar.right {
	display: none !important;
}
.header {
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	height: 100px;
	padding-left: 20px; 
	.container {
		display: flex;
		align-items: center;
		height: 100%;
	  }


	.nav_active {
		border: 1px solid var(--menu) !important;
	}
	background: var(--dark-gray)  !important;
	position: absolute;
    top: 0;
    width: 100%;
    z-index: 99999;
    
	.navbar-nav {
        z-index: 99999;
		padding-left: 80px;
		a.nav-link {
			display: block;
			padding: 0.5rem 1rem;
			color: var(--menu);
			border-radius: 12px;

			&:hover,
			&:focus {
				color: var(--menu-hover);
			}
		}
	}
	.social_links {
		padding: 0.4rem 0rem;
		ul {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 0rem 0rem 0rem 1rem;
			li {
				display: inline-block;
				a {
					padding-left: 15px;
					display: inline-block;
				}
			}
		}
	} 
	a.navbar-brand {
		img {
			margin-top: 20px;
		}
	}
	@media screen and (max-width: 1337px) {
		a.navbar-brand {
			margin-right: 0;
			img {
				//width: 210px;
				//height: auto;
				width: auto;
    			height: auto;
    			max-height: 60px;
			}
		}
		.navbar-dark .navbar-toggler {
			border-color: var(--menu);
			background: var(--menu);
			font-size: 15px;
			.navbar-toggler-icon {
				fill: #ffffff;
			}
		}
	}
}
@media screen and (max-width: 768px) {
    .header {
      position: static;
    }
  }

@media screen and (max-width: 1200px) {
	#basic-navbar-nav {
        z-index: 99999;
	    position: absolute;
	    top: 93px;
	    right: 0;
	    background: white;
	    padding: 8px 28px;
	    width: 100%;
	}
	.header .col-sm-9, .header .col-sm-9 .navbar-expand-xl {
	    position: unset;
	}
}

.content .breadcrumb {
    margin-top: 70px;
}


.footer {
    background: var(--footer-color) !important;
    color: var(--footer-text);
    .widget {
        h4 {
            margin-bottom: 5px;
            font-size: 14px;
            color: var(--footer-heading-color) !important;
        }
        a.nav-link {
            color: var(--footer-text);
            display: block;
            padding: 0 0 6px 0;
            &:last-child {
                border: 0 !important;
            }
            &:hover,
            &:focus {
                color: var(--footer-text-hover);
            }
        }
    }
    .navbar-expand.navbar {
        flex-flow: column;
        justify-content: flex-start;
        align-items: start;
        padding: 0;

    }
    .address {
        color: var(--footer-text);
        a {
            color: var(--footer-text);
            &:hover,
            &:focus {
                color: var(--footer-text-hover);
            }
        }
    }
    .recent-post {
        ul {
            padding: 0;
            list-style: none;
            li {
                margin-bottom: 15px;
                display: block;
                .post-price {
                    background: var(--primary);
                    font-size: 14px;
                    color: var(--white);
					border-radius: 12px;
                }
                .recent-post-content {
                    h5 {
                        font-size: 16px;
                        line-height: 1;
                        color: var(--footer-heading-color) !important;
                    }
                    p {
                        line-height: 1;
                        font-size: 14px;
                        color: var(--footer-heading-color) !important;
                    }
                }
                .recent-post-thumb img {
                    max-height: 80px;
                    max-width: 100px;
					border-radius: 12px;
                }
            }
        }
    }
    
    .social_links {
        ul {
            display: flex; 
            justify-content: flex-start;
            margin-top: 15px;
            li {
                display: inline-block;
                margin-right: 10px;
                a {
                    width: 30px;
                    height: 30px;
                    line-height: 28px;
                    text-align: center;
                    display: inline-block;
                    border: 1px solid var(--primary);
                    background-color: var(--primary);
                    color: var(--white);
                    &:hover {
                        background-color: var(--white);
                        border: 1px solid var(--primary);
                        color: var(--primary);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $laptop) {
	.footer {
	  height: 125px;
	}
  }
  @media screen and (max-width: $tablet) {
	.footer {
	  padding: 1rem 0;
  
	  .widget {
		h4 {
		  font-size: 10px;
		}
	  }
	  .address {
		font-size: 12px;
	  }
	  .recent-post {
		font-size: 10px;
	  }
	  .social_links {
		font-size: 10px;
	  }
	}
  }

.footer-copyright {
    background-color: var(--footer-color) !important;
    color: var(--footer-text);
    .copyright-content {
        div{
            span.xs {       
                font-size: 12px;
                color: var(--footer-heading-color) !important;
            }
        }
    }
    .gototop {
        font-size: 12px; 
      }

}

.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
img.slider_options {
    max-height: 600px;
    min-height: 600px;
    max-width: 100%;
}
.slider_section {
    margin-top: -30px;
    position: relative;

    .carousel-caption {
        h1 {
            font-size: 52px;
            font-weight: 400;
            text-shadow: 2px 2px 5px  #555555;
        }
    }

    .search_filter {
        background: rgba(0, 0, 0, 0.5);
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-48%, -50%);
        -ms-transform: translate(-48%, -50%);
        transform: translate(-48%, -50%);
        position: absolute;
        width: 100%;
        max-width: 1200px;
        padding: 20px 5px !important;
        z-index: 9999;
        border-radius: 10px;
        .form-control {
            background-color: var(--white);
            font-size: 14px;
            box-shadow: none;
        }
        .multiselect-container {
            background-color: var(--white);
            font-size: 14px;
            box-shadow: none;
        }
        .btn {
            box-shadow: none;
        }
        .col {
            padding: 0 25px;
            &:first-child {
                padding-left: 40px;
            }
            &:last-child {
                padding-right: 40px;
            }
        }
    }
}
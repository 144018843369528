@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

//theme color
:root {
    --dark: #231f20;
    --light: #f4f5f6;
    --extra-light: #cccccc;
    --primary: #ef544b;
    --secondry: #ef544b;
    --dark-gray: #414142;
    --blue: #007bff;
    --white: #fff;
    --gray: #6c757d;
    --menu: #000;
    --menu-hover: #ef544b;
    --footer-color: transparent;
    --footer-text: #000;
    --footer-text-hover: #ef544b;
    --footer-heading-color: #000000;
}
$XXLdesktop: 2400px; //xxxl
$XLdesktop: 1600px; //xxl
$desktop: 1200px; //xl
$laptop: 1024px; //lg
$tablet: 768px; //md
$phone: 480px; // sm
$smallphone: 100px; // xs

// theme fonts
:root {
    --theme-font-heading: "Ubuntu", sans-serif;
    --theme-font-text: "Open Sans", sans-serif;
}
body {
    font-size: 14px;
    font-family: var(--theme-font-text);
    font-weight: 400;
    color: var(--gray);
}
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong,
label {
    font-family: var(--theme-font-heading);
    color: var(--dark);
    font-weight: 600;
}
img {
    max-width: 100%;
}
.text-primary {
    color: var(--primary) !important;
}
.bg-primary {
    background-color: var(--primary) !important;
    border-radius: 12px;
}
.bg-dark-grey {
    background-color: var(--dark-gray) !important;
}
.text-light {
    color: var(--light) !important;
}
.text-gray {
    color: var(--gray) !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: var(--primary);
}

.h1,
h1 {
    font-size: 32px;
}
.h2,
h2 {
    font-size: 28px;
}

.h-120 {
    height: 126% !important;
}

.xs {
    height: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}
.sm {
    height: 30px;
    font-size: 36px;
    font-weight: 600;
    line-height: 30px;
}
.md {
    height: 40px;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
}
.lg {
    height: 50px;
    font-size: 45px;
    font-weight: 600;
    line-height: 45px;
}
.xl {
    height: 60px;

    font-size: 55px;
    font-weight: 600;
    line-height: 55px;
}
.xxl {
    height: 70px;
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
}
.xxxl {
    height: 80px;
    font-size: 70px;
    font-weight: 600;
    line-height: 70px;
}
a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
    -webkit-transition: all 0.5s ease-in-out 0.05s;
    -moz-transition: all 0.5s ease-in-out 0.05s;
    -o-transition: all 0.5s ease-in-out 0.05s;
    transition: all 0.5s ease-in-out 0.05s;
    &:hover,
    &:focus {
        color: var(--primary);
        text-decoration: none;
    }
}

.btn,
.form-control {
    border-radius: 12;
}
.btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary.focus,
.btn-primary:focus {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 50%);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

.form-control {
    height: 50px;
    line-height: 35px;
    background-color: var(--light);
    border-color: var(--extra-light);
}

::selection {
    color: var(--white);
    background: var(--primary);
}

.subheading {
    position: relative;
    &:before {
        position: absolute;
        background-color: var(--primary);
        height: 2px;
        width: 50px;
        content: "";
        display: inline-block;
        bottom: 0;
        left: 0;
    }
}

.heading {
    position: relative;
    &:before {
        position: absolute;
        background-color: var(--extra-light);
        height: 1px;
        width: 100%;
        content: "";
        display: inline-block;
        bottom: 0;
        left: 0;
    }
}

.breadcrumb {
    background-color: transparent;
    padding-left: 0;
}

.page-link {
    color: var(--dark);
    border-width: 4px 0 0 0;
    border-color: var(--light);
    padding: 0.5rem 1.2rem;
    font-weight: 600;
    font-size: 15px;
}

.page-item.active .page-link {
    z-index: 3;
    border-width: 4px 0 0 0;
    color: var(--primary);
    background-color: transparent;
    border-color: var(--primary);
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-color: transparent;
}

.page-link:hover {
    z-index: 2;
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
    border-color: var(--primary);
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.aside_widget {
    .cancelpolicy {
        padding: 0 0 0 10px;
        li {
            list-style-type: disc;
        }
    }
    .list {
        li {
            ul {
                li {
                    width: 50%;
                    display: inline-block;
                    padding: 5px 5px 0px 15px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        width: 5px;
                        height: 5px;
                        display: inline-block;
                        background-color: var(--primary);
                        left: 0;
                        top: 11px;
                    }
                }
            }
        }
    }
    .form-control-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;

        background: var(--primary);
        cursor: pointer;
    }

    .form-control-range::-moz-range-thumb {
        background: var(--primary);
        cursor: pointer;
    }
}
.carousel-control-next,
.carousel-control-prev {
    font-size: 70px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: var(--secondry);
}
.daterangepicker td.in-range {
    background-color: #fff9f8;
    color: var(--dark);
}
.daterangepicker td.end-date.in-range {
    background-color: var(--secondry);
}
.modal {
    .modal-content {
        border-radius: 0;
        padding: 30px 40px;
        .modal-header {
            padding: 0 0 20px 0;
            margin-bottom: 20px;
        }
        .modal-body {
            padding: 0;
        }
    }
}
.border-0.table thead th,
.border-0.table tbody td {
    border: 0;
}
.display-5 {
    font-size: 26px;

    line-height: 1.2;
}
.cursor-pointer {
    cursor: pointer;
}

.modal-header .close {
    position: absolute;
    right: 15px;
    top: 15px;
}


@media screen and (min-width: 1128px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1760px !important;
        padding-right: 80px !important;
        padding-left: 80px !important;
    }
}

// @media screen and (min-width: $desktop) {
//     .container, .container-lg, .container-md, .container-sm, .container-xl {
//         max-width: 1170px;
//     }
// }

// @media screen and (min-width: $XLdesktop) {
//     .container, .container-lg, .container-md, .container-sm, .container-xl {
//         max-width: 1200px;
//     }
// }
// @media screen and (min-width: $XXLdesktop) {
//     .container, .container-lg, .container-md, .container-sm, .container-xl {
//         max-width: 1200px;
//     }
// }
@media screen and (max-width: 1200px) {
    .property-card {
        img.card-img-top {
            min-height: 150px;
            max-height: 150px;
        }
    }
}


@media screen and (max-width: $tablet) {
    .property-card {
        img.card-img-top {
            min-height: 150px;
            max-height: 150px;
        }
    }

    .search_filter {
        display: block;
        .col {
            margin-bottom: 15px;
        }
    }

    .slider_section {
        .search_filter {
            max-width: 400px;
            .col {
                margin: 0 0 10px 0;
            }
        }
        .carousel-caption {
            bottom: 20px;
            height: auto !important;

            h1 {
                font-size: 20px;
                line-height: 30px;
                text-shadow: 0px 0px 5px var(--dark), 0px 0px 5px var(--dark);
            }
        }
        .search_filter {
            display: block;
            position: relative;
            transform: none;
            left: 0;
            margin: 15px auto;
            .col:first-child {
                padding-left: 25px;
            }
            .col:last-child {
                padding-right: 25px;
            }
        }
    }
    h2 {
        font-size: 18px;
    }
}

@media screen and (max-width: $phone) {
    .property-card {
        img.card-img-top {
            min-height: auto;
            max-height: initial;
        }
    }
    .header.py-4 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .slider_section {
        .carousel-caption {
            bottom: 20px;
            height: auto !important;

            h1 {
                font-size: 20px;
                line-height: 30px;
                text-shadow: 0px 0px 5px var(--dark), 0px 0px 5px var(--dark);
            }
        }
        .search_filter {
            max-width: 250px;
            .col {
                margin: 0 0 10px 0;
            }
        }
    }
    .title-bar {
        a {
            font-size: 12px;
        }
    }
    .footer {
        .widget {
            margin-bottom: 10px;
            h4 {
                font-size: 14px;
                margin-bottom: 2px;
            }
        }
        .links {
            a.nav-link {
                color: var(--dark);
                padding: 0 0 5px 0;
            }
        }
    }
    .footer-copyright {
        text-align: center;
        .copyright-content {
            div {
                span {
                    display: block;
                    padding-left: 0 !important;
                }
            }
        }
    }
    .pagination {
        a.page-link {
            padding: 0.5rem 0.8rem;
        }
    }
}

.Confirmation {
    .card-body {
        padding: 30px 80px;
        box-shadow: -2px -2px 10px var(--light), 3px 3px 10px var(--dark-gray);
        table{
            td, th{
                &:nth-child(2){
                    text-align: right;
                }
            }
        }
    }
    .Confirmation-heading {
        
        p {
            strong {
                font-size: 18px;
            }
        }
    }
    address{
        .adr_info{
            p{
                span{
                    display: flex;                   
                }
               
                margin-bottom: 5px;
            }
        }
        .disabled{
            pointer-events: none;
        }
       
    }
    hr{
        border-width: 2px;
        border-color: var(--extra-light);
    }
}


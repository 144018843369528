.single_prop{
    h1{
        font-size: 0px;
    }
}
.see-all-images-button {
    position: absolute;
    bottom: 10px;
    right: 50px;
    background-color: rgba(0,0,0,0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
}

.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    cursor: pointer;
}

.booknow-sidebar {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    position: sticky;
    top: 0;
}
.wrapper-col {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
.rooms_card{
    border: 1px solid gray;
    border-radius: 12px;
}
.map_loc_container{
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 2px solid red;
    border-radius: 50%;
    display: inline-block;
}

.book_sidebar{
    width: 100%;
    height: 100%;
    top: 0;
    .book_sidebar_sticky{
        position: sticky;
        top: 0;
    }
}

.CustomTranslateButton {
    display: block;
    padding: 0.5rem 1rem;
    color: var(--menu);
    background-color: transparent;
    border: none;
    font-family: inherit;
    cursor: pointer;
    font-size: 15px;
    padding: 2px 4px;
    height: auto;
    width: 150px;
  }
  
  .CustomTranslateButton:hover,
  .CustomTranslateButton:focus {
    color: var(--menu-hover);
    outline: none;
  }
  
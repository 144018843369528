.navbar-brand{
    img{
       // height: 25px;
       width: auto;
    	height: auto;
    	max-height: 60px;
    }    
    .blackText {
    	color: #000;
    }
}
.more_filter{
    .form-control{
        height: 42px;
        background-color: var(--light);
        border: 0;
        box-shadow: -1px 2px 3px var(--extra-light);
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 12px;
    }
    .radiusedge {
        border-radius: 12px;
    }
}
.search_filter{
    .dropdown{
        border-radius: 12px; 
    }
    .drp-buttons{
        display: none !important;
    }
    .multiselect-container{
        min-height: 42px;
        background-color: var(--light);
        border: 0 !important;
        box-shadow: -1px 2px 3px var(--extra-light);
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 12px; 
        .search-wrapper{
            border: 0 !important;
        }
    }
    .form-control{
        height: 42px;
        background-color: var(--light);
        border: 0;
        box-shadow: -1px 2px 3px var(--extra-light);
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 12px;
    }
    .btn {
        height: 42px;
        box-shadow: -1px 2px 3px var(--extra-light);
        border-radius: 12px; 
    }
}
.DateRangePickerInput {
    border-radius: 12px;


  }
  .DateInput_input__small {
    border-radius: 12px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    min-height: 42px;
    width: 115%;

}
  .DateRangePickerInput__withBorder {
 
    border-radius: 12px;
    box-shadow: -1px 1px 1px;
     width: 115%;

  }
  .DateInput {
    background: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    
  }

  .datepicker-wrapper {
    display: flex;
    align-items: center;
    width: 120%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    border: 0;
}
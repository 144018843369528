.category-card {
    position: relative;
    overflow: hidden;
    box-shadow: 2px 2px 10px var(--extra-light);

    img.card-img-top {
        position: relative;
        // min-height: 200px;
        // max-height: 200px;
        aspect-ratio: 8/5;
    }
    .card-body {
        padding: 0;
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;
        transition: all 0.5s ease;
        .card-title {
            color: var(--white);
            font-size: 16px;
            padding: 0 15px;
            margin: 0;
            white-space: nowrap;
            font-weight: 600;
            text-shadow: 0 0 5px var(--dark), 0 0 5px var(--dark);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

$card-size: 350px;
$p-m-default: 20px;

$brand-blue: #2C3E50;
$brand-green: #16A085;

$black: #111;
$white: #FFF;

// Easing Properties
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);

// PX to EM
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

// Transition Mixin
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
 
.about-gallery{
    .card {
        // background-color: $black;
        background-image: linear-gradient(0deg, $brand-blue 0%, $brand-green 100%);  
        width: 100%;
        margin-top: $p-m-default;
        margin-bottom: $p-m-default;
        // border-radius: 8px;
        position: relative;
        overflow: hidden;
        // backface-visibility: hidden;
      }
      .card-image {
        // height: $card-size;
        object-fit: cover;
        transform: translate(0,0);
        @include transition(all 400ms $easeOutQuad);
      }
      .card-meta {
        font-size: em(11);
        text-transform: uppercase;
        letter-spacing: 1px;
        &:before {
          content: '';
          height: 1px;
          width: 30px;
          background-color: #fff;
          position: relative;
          display: block;
          margin-bottom: 10px;
          backface-visibility: hidden;
          opacity: 0;
          transform: translate(0,-10px);
          @include transition(all 200ms $easeOutQuad);
        }  
      }
      .card-text {
        width: 100%;
      color: #FFF;
      background-color: rgba($black, .65);
      position: absolute;
      padding: $p-m-default;
      z-index: 10;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-end;
      @include transition(all 200ms $easeOutQuad);
    }
    .card-title {
        color: #fff;
      margin: 40px 0;
      font-weight: 300;
      font-size: em(30);
    }
      
  .card a {
    &:hover {
      .card-text {
        background-color: rgba($black, .86);
        // color: $black;
      }
      .card-meta {
        &:before {
          transform: translate(0,0);
          // background-color: $black;
          opacity: 1;
          @include transition(all 200ms $easeOutQuad);
        }
      }
      
      .card-image {
        transform: translate(20px,0);
        @include transition(all 400ms $easeOutQuad);
      }    
    }  
  }

}


  
  
  

  

  


.payment {
    margin-bottom: 40px;
    h4 {
        font-size: 30px;
    }
    .payment-card {
        span {
            font-size: 38px;
            padding-left: 0;
            padding-top: 20px;
            text-align: center;
            padding-right: 0;
            color: var(--gray);
        }
    }
    .btn-primary {
        padding: 10px 70px;
    }
    .form-control {
        height: 42px;
        background-color: var(--light);
        border: 0;
        box-shadow: -1px 2px 3px var(--extra-light);
        padding: 5px 10px;
        font-size: 13px;
    }
    textarea.form-control {
        height: auto;
    }
    .form-group {
        margin-bottom: 20px;
    }
}

.confirm-info {
    .form-check {
        margin-top: 42px;
    }
}

.prod_details {
    .accordion_list {
        li {
            button {
                width: 100%;
                border: none;
                background-color: transparent;
                display: flex;
                text-align: left;
                padding: 5px 0;
            }
            .card-body {
                .table td {
                    border: 0;
                    padding: 2px 0;
                    &:nth-child(2) {
                        text-align: right;
                    }
                }
                background-color: #f5f7f9;
                border: 1px solid #eef7ff;
                a{
                   svg{
                    font-size: 25px;
                   } 
                }
            }
        }
    }
    .total-order {
        h3 {
            strong {
                font-size: 40px;
            }
        }
    }
}

.custom-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: -1px 2px 3px var(--extra-light);
    height: 42px;
    background-color: var(--light);
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 12px; 
  }
  .custom-dropdown.white {
    border-color: white;
    background-color: white;
    box-shadow: none;
  }
  
  .dropdown-title {
    cursor: pointer;
  }
  
  .dropdown-options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid var(--extra-light);
    box-shadow: -1px 2px 3px var(--extra-light);
    border-radius: 12px; 
  }
  .custom-dropdown.white .dropdown-options {
    box-shadow: none;
  }
  
  .custom-dropdown:hover .dropdown-options {
    display: block;
  }
  
  .dropdown-option {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
  }

  .dropdown-option input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    border: 1px solid #333;
    height: 16px;
    width: 16px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
  }
  
  .dropdown-option input[type="checkbox"]:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }
  
  .dropdown-option input[type="checkbox"]:checked:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  
  .dropdown-option:hover,
  .dropdown-option.selected {
    background-color: var(--light);
  }
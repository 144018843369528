.image-banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin-bottom: 20px;
}

.primary-image-container,
.secondary-image-container {
    height: 100%;
    display: flex;
}

.primary-image-container img,
.secondary-image-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.primary-image-container {
    padding-right: 0px; /* remove padding */
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden; /* makes sure the image follows the border radius */
}

.secondary-image-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 5px; 
    padding: 0px 0px 0px 0px; /* remove padding */
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden; /* makes sure the image follows the border radius */
}
